import { StyledKeyBox } from "./style";
import { StyledBetweenAlignFlex } from "../../../style/common";
import ValuesButton from "../../atoms/ValuesButton";
import { KEY_BOX_DATA } from "../../../mock/keyBoxData";
import KeyBoxCard from "../../atoms/KeyBoxCard";
import { useState } from "react";
import { useAudioContext } from "../../../contexts/audio";

const KeyBox = () => {
  const { isMuted } = useAudioContext();

  const [variant, setVariant] = useState("less"); // ['less' , 'more']
  // handle click on switch values button
  const handleValueBtnClick = () => {
    setVariant(variant === "less" ? "more" : "less");
  };

  const handleKeyBoxCardClick = (index) => {
    if (!isMuted) {
      const audio = new Audio("/assets/audios/coins-click.mp3");
      audio.play();
    }
    setSelectedValue(index);
  };
  const [selectedValue, setSelectedValue] = useState(0); // [0,1,2,3] index of value
  return (
    <StyledKeyBox
      variant={selectedValue !== undefined ? "selected" : "not-selected"}
    >
      <div className="box">
        <StyledBetweenAlignFlex gap="8px" className="header_box">
          <ValuesButton variant={variant} onClick={handleValueBtnClick} />
          <div className="hr"></div>
          <p>صندوق المفاتيح</p>
        </StyledBetweenAlignFlex>
        <StyledBetweenAlignFlex gap="6px" margin="6.77px 0 0">
          {/* data divide to two array */}
          {/* less or more */}
          {/* the structure of data is like this:
          {
            "less" : [....],
            "more" : [....]
          } */}
          {KEY_BOX_DATA[variant].map(({ id, value }, index) => (
            <KeyBoxCard
              key={id}
              onClick={() => handleKeyBoxCardClick(index)}
              selected={index === selectedValue}
              value={value}
            />
          ))}
        </StyledBetweenAlignFlex>
      </div>
    </StyledKeyBox>
  );
};

export default KeyBox;
