import { useEffect, useRef, useState } from "react";
import AppLayout from "./components/organism/AppLayout";
import { useAudioContext } from "./contexts/audio";
import { useInfoContext } from "./contexts/info";

function App() {
    const ref = useRef(null);
    // global value for mute and unmute
    const { isMuted } = useAudioContext();
    const { setAuthToken } = useInfoContext();
    const [audio, setAudio] = useState(
        new Audio("/assets/audios/background.mp3")
    );
    useEffect(() => {
        // decrease volume of background music
        audio.loop = true;
        audio.volume = 0.5;
        if (isMuted) audio.pause();
        else audio.play();
    }, [audio, isMuted]);

    useEffect(() => {
        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    const handleMessage = (event) => {
        // Check if the event data is the authentication token
        if (event.data && typeof event.data === "string") {
            const authToken = event.data;
            setAuthToken(setAuthToken);
            // Use the authentication token received from the WebView
            console.log("Received authentication token:", authToken);
        }
    };
    return (
        <div className="App">
            <AppLayout />
        </div>
    );
}

export default App;

// comments of developer
// to handle data of user [ avatar , name , id , level , progress , coinsBalance , NumberOfRounds ]
// => in folder contexts => info.js u can handle api there , and use set method to set data of user
// to handle data of selected card [ img , rate ]
// => in folder contexts => info.js u can handle api there , and use set method to set data of selected card
// to handle data of round point [ roundPoint ] "number of points user in earn in round"
// => in folder contexts => info.js u can handle api there , and use set method to set data of round point

// to handle data of mute and unmute [ isMuted ] "boolean"
// => in folder contexts => audio.js u can handle api there , and use set method to set data of mute and unmute

// to show how cards are handled
// => in folder mock u can see how cards data are handled

// to show how winners bottom carousel are handled
// => in folder mock u can see how winners bottom carousel data are handled

// any thing u need to ask me , u can ask me in whatsapp .

// to handle timer of 30 secs , go up in this file u will found setTimeOut function , inside of it will excute after 30 secs

// start of loop after click to luck button
// => in folder utils => loop.js u can see how loop is handled
