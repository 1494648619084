export const WINNERS_BOX_DATA = [
  {
    id: 1,
    itemSrc: "/assets/apricot.png",
    itemAltSrc: "apricot",
  },
  {
    id: 2,
    itemSrc: "/assets/Strawberry.png",
    itemAltSrc: "Strawberry",
  },
  {
    id: 3,
    itemSrc: "/assets/cherry.png",
    itemAltSrc: "cherry",
  },
  {
    id: 4,
    itemSrc: "/assets/Dahab.png",
    itemAltSrc: "Dahab",
  },
  {
    id: 5,
    itemSrc: "/assets/watermelon.png",
    itemAltSrc: "watermelon",
  },
  {
    id: 6,
    itemSrc: "/assets/apple.png",
    itemAltSrc: "apple",
  },
  {
    id: 7,
    itemSrc: "/assets/kiwi.png",
    itemAltSrc: "kiwi",
  },
  {
    id: 8,
    itemSrc: "/assets/grapes.png",
    itemAltSrc: "grapes",
  },
  {
    id: 9,
    itemSrc: "/assets/green-apple.png",
    itemAltSrc: "green-apple",
  },
  {
    id: 10,
    itemSrc: "/assets/banana.png",
    itemAltSrc: "banana",
  },
]