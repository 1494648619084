import { StyledHeader } from "./style";
import { StyledAlignFlex } from "../../../style/common";
import CloseButton from "../../atoms/CloseButton";
import AvatarContent from "../../atoms/AvatarContent";

const Header = ({ handleDrawerShow }) => {
  return (
    <StyledHeader as="header" padding="25px 10px 0" margin="0 0">
      <StyledAlignFlex gap="6px" className="control">
        <CloseButton width="40px"  />
        <div onClick={handleDrawerShow}>
          <img src="/assets/exclamation-icon.png" alt="exclamation icon" />
          <p>التعليمات</p>
        </div>
      </StyledAlignFlex>
      <AvatarContent />
    </StyledHeader>
  );
};

export default Header;
