import styled from "styled-components";

export const StyledCursorWrapper = styled.div`
  position: absolute;
  top: ${props => props.top || 0};
  left: ${props => props.left || 0};
  z-index: 999;
  pointer-events: none;
  width: 29px;
  height: 33px;
  transition: 0.5s;
  img {
  }
`