import styled from "styled-components";
import { StyledFlexCenter } from "../../../style/common";

export const StyledGameItemCard = styled(StyledFlexCenter)`
  background-image: ${props => props.selected ? 'url("assets/gold-item-box.png")':'url("assets/item-box.png")'};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-width: 59.8px;
  min-height: 61.762px;
  height: 29.3%;
  width: 24.5%;
  position: relative;
  cursor: pointer;

  img.item {
    position: absolute;
    left: 55%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .rate {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
  }

  .rate img:first-child {
    width: 9px;
    height: 16px;
  }

  .rate img:last-child {
    width: 45px;
    height: 24px;
  }
  .badge{
    position: absolute;
    top: -55%;
    left: 55%;
    width: 50%;
    text-align: center;
    background-color: rgba(0,56,255,0.5) ;
    box-shadow: 0px 0px 5px #ffad00;
    color: #fff;
    transform: translate(-50%, 2%);
    border-radius: 7px;
  }
  @media (min-width : 450px){
    .badge{
      top: -30%;
      left: 55%;
    }
  }
`;
