import { createContext, useContext, useState } from "react";

const audioContext = createContext();

export const useAudioContext = () => useContext(audioContext);

export default function AudioContextProvider({ children }) {
  const [ isMuted, setIsMuted ] = useState(false);
  const toggleMute = () => setIsMuted(!isMuted);
  return <audioContext.Provider value={{ isMuted, toggleMute }}>{children}</audioContext.Provider>;
}

