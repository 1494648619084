import styled from "styled-components";

export const StyledKeyBox = styled.section`
  margin-bottom: 30px;
  .box {
    padding: 10px 10px 15px;
    width: 100%;
    margin: auto;
    border-radius: 7px;
    border: 3px solid #372e99;
    background: #27156f;
    box-shadow: 0px 1px 8px 4px rgba(0, 0, 0, 0.44) inset;
    position: relative;
  }

  .box::after {
    content: "";
    position: absolute;
    background-image: url("assets/WavySide.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 29px;
    bottom: -27px;
    left: 50%;
    transform: translateX(-50%);
    mix-blend-mode: screen;
  }

  .header_box .hr {
    flex: 1;
    height: 3px;
    background-color: var(--purple4);
  }

  p {
    color: ${props => props.variant === 'selected' ? 'var(--janzari-color)' : 'var(--bold-pink)'};
    text-align: right;
    font-family: "Cairo", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: 122.9%; /* 12.29px */
  }

  @media (max-width: 399px) {
  }

  @media (max-width: 368px) {
    .value_button {
      width: 65.859px;
      height: 21.292px;
      font-size: 10px;
    }
    .hr {
      height: 0.968px;
    }
    p {
      font-size: 10px;
    }
  }

  @media (min-width: 369px) and (max-width: 429px) {
    .value_button {
      width: 75.859px;
      height: 23.292px;
      font-size: 12px;
    }
    .hr {
      height: 2px;
    }
    p {
      font-size: 13px;
    }
  }

  @media (min-width: 430px) and (max-width: 599px) {
    .value_button {
      width: 80.859px;
      height: 25.292px;
      font-size: 14px;
    }
    .hr {
      height: 2.5px;
    }
    p {
      font-size: 16px;
    }
  }
`;
