import React, { forwardRef } from "react";
import { StyledCursorWrapper } from "./style";

const Cursor = ({ top, left }, ref) => {
  return (
    <StyledCursorWrapper top={top} left={left} ref={ref}>
      <img src="/assets/cursor.png" alt="" />
    </StyledCursorWrapper>
  );
};

export default forwardRef(Cursor);
