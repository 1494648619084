import styled from "styled-components";

export const StyledCloseButton = styled.div`
  text-align: center;
  cursor: pointer;
  
  img {
    width: ${(props) => props.width};
  }

  p {
    color: var(--white-color);
    text-align: center;
    font-family: "Cairo", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 122.9%;
  }
`;
