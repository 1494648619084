import styled from "styled-components";
import { StyledBetweenAlignFlex } from "../../../style/common";

export const StyledWinningResults = styled(StyledBetweenAlignFlex)`
  img.voice {
    max-width: 100%;
    width: 50px;
    cursor: pointer;
    position: relative;
  }

  .result_box {
    max-width: 370px;
    height: 65px;
    border-radius: 8px;
    background: var(--purple6);
    position: relative;
  }

  .box {
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    scroll-snap-type: inline mandatory;
  }

  .box::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .child_box {
    width: 160%;
  }

  .result_box .text {
    padding-left: 10px;
    position: relative;
  }

  .result_box .text::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    border-radius: 19px;
    background: var(--third-color);
  }

  span {
    display: block;
    color: var(--white-color);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 122.9%; /* 9.832px */
  }

  .title span {
    display: block;
    width: 93.48px;
    height: 19.243px;
    color: var(--white-color);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .title span:first-child {
    margin-bottom: 2px;
  }

  .title + img {
    width: 50.874px;
    height: 50.051px;
    border-radius: 41px;
    border: 2px solid var(--white-color);
  }

  @media (max-width: 350px) {
    img.voice {
      width: 25px;
      height: 25px;
    }

    .result_box {
      width: 180px;
      height: 30px;
    }

    .WinningResultCard {
      width: 21.259px;
      height: 20.651px;
    }

    .WinningResultCard img {
      width: 13.97px;
      height: 15.185px;
    }

    .result_box .text::before {
      width: 1px;
      height: 21.001px;
    }

    span {
      font-size: 8px;
    }

    .title span {
      width: 50.48px;
      height: 9.243px;
      font-size: 8px;
    }

    .title + img {
      width: 23.874px;
      height: 23.051px;
    }
  }

  @media (min-width: 351px) and (max-width: 364px) {
    img.voice {
      width: 25px;
      height: 25px;
    }

    .result_box {
      width: 208px;
      height: 33px;
    }

    .WinningResultCard {
      width: 21.259px;
      height: 20.651px;
    }

    .WinningResultCard img {
      width: 13.97px;
      height: 15.185px;
    }

    .result_box .text::before {
      width: 1px;
      height: 21.001px;
    }

    span {
      font-size: 8px;
    }

    .title span {
      width: 50.48px;
      height: 9.243px;
      font-size: 8px;
    }

    .title + img {
      width: 23.874px;
      height: 23.051px;
    }
  }

  @media (min-width: 365px) and (max-width: 399px) {
    img.voice {
      width: 25px;
      height: 25px;
    }

    .result_box {
      width: 208px;
      height: 33px;
    }

    .WinningResultCard {
      width: 21.259px;
      height: 20.651px;
    }

    .WinningResultCard img {
      width: 13.97px;
      height: 15.185px;
    }

    .result_box .text::before {
      width: 1px;
      height: 21.001px;
    }

    span {
      font-size: 8px;
    }

    .title span {
      width: 50.48px;
      height: 9.243px;
      font-size: 8px;
    }

    .title + img {
      width: 23.874px;
      height: 23.051px;
    }
  }

  @media (min-width: 400px) and (max-width: 429px) {
    img.voice {
      width: 30px;
    }

    .result_box {
      width: 220px;
      height: 40px;
    }

    .child_box {
      width: 180%;
    }

    .WinningResultCard {
      width: 25.259px;
      height: 24.651px;
    }

    .WinningResultCard img {
      width: 18.97px;
      height: 18.185px;
    }

    span {
      font-size: 9px;
    }

    .title span {
      width: 60.48px;
      height: 13.243px;
      font-size: 9px;
    }

    .title + img {
      width: 30.874px;
      height: 30.051px;
    }
  }

  @media (min-width: 430px) and (max-width: 469px) {
    img.voice {
      width: 40px;
    }

    .result_box {
      width: 250px;
      height: 45px;
    }

    .child_box {
      width: 180%;
    }

    .WinningResultCard {
      width: 30.259px;
      height: 29.651px;
    }

    .WinningResultCard img {
      width: 23.97px;
      height: 23.185px;
    }

    span {
      font-size: 10px;
    }

    .title span {
      width: 67.48px;
      height: 15.243px;
      font-size: 10px;
    }

    .title + img {
      width: 40.874px;
      height: 40.051px;
    }
  }

  @media (min-width: 470px) and (max-width: 509px) {
    img.voice {
      width: 40px;
    }

    .result_box {
      width: 260px;
      height: 50px;
    }

    .child_box {
      width: 190%;
    }

    .WinningResultCard {
      width: 30.259px;
      height: 29.651px;
    }

    .WinningResultCard img {
      width: 23.97px;
      height: 23.185px;
    }

    span {
      font-size: 11px;
    }

    .title span {
      width: 67.48px;
      height: 15.243px;
      font-size: 11px;
    }

    .title + img {
      width: 40.874px;
      height: 40.051px;
    }
  }

  @media (min-width: 510px) and (max-width: 599px) {
    img.voice {
      width: 45px;
    }

    .result_box {
      width: 300px;
      height: 60px;
    }

    .child_box {
      width: 190%;
    }

    .WinningResultCard {
      width: 40.259px;
      height: 39.651px;
    }

    .WinningResultCard img {
      width: 30.97px;
      height: 30.185px;
    }

    span {
      font-size: 13px;
    }

    .title span {
      width: 85.48px;
      height: 19.243px;
      font-size: 13px;
    }

    .title + img {
      width: 45.874px;
      height: 45.051px;
    }
  }
`;
