import styled from "styled-components";
import { StyledFlexCenter } from "../../../style/common";

export const StyledValuesButton = styled(StyledFlexCenter)`
  color: var(--white-color);
  width: 65.86px;
  height:21.292px;
  border-radius: 7px;
  border: none;
  outline: none;
  background-color: ${(props) => props.variant === "less" ? "var(--bold-pink)" : "var(--janzari-color)"};
  text-align: center;
  font-family: "Cairo", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 122.9%; /* 12.29px */
  cursor: pointer;
  transition: all 0.3s;
`;
