import React, { forwardRef, useEffect, useState } from "react";
import { StyledGameItemCard } from "./style";
import { StyledAlignFlex } from "../../../style/common";
import { useInfoContext } from "../../../contexts/info";

const GameItemCard = (
  { itemSrc, itemAltSrc, rate, selected, ...props },
  ref
) => {
  const [rateSrc, setRateSrc] = useState("");
  const [chargerSrc, setChargerSrc] = useState("");
  const [showBadge, setShowBadge] = useState(true);
  const { selectedCoin } = useInfoContext();
  useEffect(() => {
    switch (rate) {
      case "5x":
        setRateSrc("/assets/x5.png");
        setChargerSrc("/assets/little-charger.png");
        break;
      case "10x":
        setRateSrc("/assets/x10.png");
        setChargerSrc("/assets/lower-average-charger.png");
        break;
      case "20x":
        setRateSrc("/assets/x20.png");
        setChargerSrc("/assets/average-charger.png");
        break;
      case "45x":
        setRateSrc("/assets/x45.png");
        setChargerSrc("/assets/almost-complete-charger.png");
        break;
      case "75x":
        setRateSrc("/assets/x75.png");
        setChargerSrc("/assets/complete-charger.png");
        break;
      default:
        setRateSrc("/assets/x5.png");
        setChargerSrc("/assets/lower-average-charger.png");
        break;
    }
  }, [rate]);

  useEffect(() => {
    if(selected){
      setShowBadge(true);
      setTimeout(() => {
        setShowBadge(false);
      }, 2000);
    }
  }, [selected]);

  return (
    <StyledGameItemCard {...props} ref={ref} selected={selected}>
      <img src={itemSrc} alt={itemAltSrc} className="item" />
      {selected  && showBadge &&<div className="badge">{selectedCoin}</div>}
      <StyledAlignFlex gap="2px" className="rate">
        <img src={chargerSrc} alt={rate} />
        <img src={rateSrc} alt={rate} />
      </StyledAlignFlex>
    </StyledGameItemCard>
  );
};

export default forwardRef(GameItemCard);
