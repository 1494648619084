import styled from "styled-components";

export const StyledDrawerParent = styled.div`
  background: linear-gradient(180deg, rgba(49, 2, 108, 0) 0%, #000 34.87%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;

  &.not_display {
    animation: hidden 1s ease-out forwards;
  }

  &.display {
    animation: show 1s ease-out forwards;
  }

  @keyframes show {
    0% {
      opacity: 0;
      display: block;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes hidden {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`;

export const StyledDrawer = styled.div`
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  background-image: url("assets/InfoBox.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1000000;

  .close_button {
    position: absolute;
    top: 177px;
    left: 76px;
  }

  .drawerPhoto {
    max-width: 100%;
    width: 27rem;
    position: absolute;
    top: -55px;
    left: 54%;
    transform: translateX(-50%);
    filter: drop-shadow(0 19px 10px #250440);
    z-index: 1000;
  }

  .drawer_text {
    position: relative;
    margin-top: 230px;
    z-index: 10000;
  }

  .drawer_text h1 {
    color: var(--white-color);
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }

  .text_box {
    max-width: 680px;
    width: 100%;
    height: 687px;
    border-radius: 24px;
    margin: auto;
    background: var(--purple7);
    padding: 13px 10px 20px 11px;
    color: var(--white-color);
    overflow-y: hidden;
  }

  .text_box .info {
    overflow-y: auto;
    overscroll-behavior-inline: contain;
    scroll-snap-type: inline mandatory;
    height: 100%;
    padding-right: 15px;
  }

  .box::-webkit-scrollbar,
  .text_box::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .text_box p {
    width: 100%;
    color: var(--white-color);
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%; /* 19.74px */
    >span{
      display: flex;
      justify-content: flex-end;
    }
  }

  //----------------------------------------------------------------------
  //-------------------------- Media Query -------------------------------
  //----------------------------------------------------------------------

  @media (max-width: 349px) {
    height: 36rem;

    .close_button {
      top: 84px;
      left: 7px;
    }

    .drawerPhoto {
      width: 18rem;
      top: -50px;
    }

    .drawer_text {
      margin-top: 160px;
    }

    .drawer_text h1 {
      font-size: 30px;
    }

    .text_box {
      max-width: 300px;
      height: 307px;
    }

    .text_box p {
      font-size: 11px;
    }
  }

  @media (min-width: 350px) and (max-width: 399px) {
    height: 37rem;

    .close_button {
      top: 86px;
      left: 15px;
    }

    .drawerPhoto {
      width: 20rem;
      top: -60px;
    }

    .drawer_text {
      margin-top: 160px;
    }

    .drawer_text h1 {
      font-size: 25px;
    }

    .text_box {
      max-width: 310px;
      height: 317px;
    }

    .text_box p {
      font-size: 12px;
    }
  }

  @media (min-width: 400px) and (max-width: 429px) {
    height: 39rem;

    .close_button {
      top: 87px;
      left: 25px;
    }

    .drawerPhoto {
      width: 22rem;
      top: -80px;
    }

    .drawer_text {
      margin-top: 160px;
    }

    .drawer_text h1 {
      font-size: 35px;
    }

    .text_box {
      max-width: 340px;
      height: 347px;
    }

    .text_box p {
      font-size: 13px;
    }
  }

  @media (min-width: 430px) and (max-width: 469px) {
    height: 43rem;

    .close_button {
      top: 100px;
      left: 25px;
    }

    .drawerPhoto {
      width: 23rem;
      top: -80px;
    }

    .drawer_text {
      margin-top: 160px;
    }

    .drawer_text h1 {
      font-size: 35px;
    }

    .text_box {
      max-width: 390px;
      height: 397px;
    }

    .text_box p {
      font-size: 13px;
    }
  }

  @media (min-width: 470px) and (max-width: 509px) {
    height: 46rem;

    .close_button {
      top: 107px;
      left: 36px;
    }

    .drawerPhoto {
      width: 25rem;
      top: -88px;
    }

    .drawer_text {
      margin-top: 190px;
    }

    .drawer_text h1 {
      font-size: 38px;
    }

    .text_box {
      max-width: 405px;
      height: 412px;
    }

    .text_box p {
      font-size: 13px;
    }
  }

  @media (min-width: 510px) and (max-width: 549px) {
    height: 50rem;

    .close_button {
      top: 120px;
      left: 41px;
    }

    .drawer_text h1 {
      font-size: 38px;
    }

    .text_box {
      max-width: 425px;
      height: 427px;
    }

    .text_box p {
      font-size: 13px;
    }
  }

  @media (min-width: 550px) and (max-width: 599px) {
    height: 54rem;

    .close_button {
      top: 130px;
      left: 31px;
    }

    .drawer_text h1 {
      font-size: 38px;
    }

    .text_box {
      max-width: 470px;
      height: 477px;
    }

    .text_box p {
      font-size: 14px;
    }
  }

  @media (min-width: 600px) and (max-width: 650px) {
    height: 59rem;

    .close_button {
      top: 145px;
      left: 40px;
    }

    .text_box {
      max-width: 540px;
      height: 547px;
    }

    .text_box p {
      font-size: 15px;
    }
  }

  @media (min-width: 651px) and (max-width: 699px) {
    height: 63rem;

    .close_button {
      left: 68px;
    }
  }

  @media (min-width: 700px) and (max-width: 729px) {
    height: 66rem;
  }

  @media (min-width: 730px) and (max-width: 768px) {
    height: 70rem;
  }

  //------------------------- Repeating -------------------------

  @media (min-width: 600px) and (max-width: 729px) {
    .drawer_text h1 {
      font-size: 40px;
    }
  }

  @media (min-width: 651px) and (max-width: 729px) {
    .close_button {
      top: 152px;
    }

    .text_box {
      max-width: 600px;
      height: 607px;
    }

    .text_box p {
      font-size: 16px;
    }
  }

  @media (min-width: 600px) and (max-width: 768px) {
    .drawer_text {
      margin-top: 270px;
    }
  }
`;
