import { useState } from "react";
import { StyledAvatar } from "./style";
import { useInfoContext } from "../../../contexts/info";

const Avatar = ({ viewLevel = true, ...props }) => {
  const {user , setUser} = useInfoContext()
  return (
    <StyledAvatar {...props} className="avatar">
      <img src="/assets/stars.png" alt="stars" className="stars" />
      <div className="image">
        <img src={user.avatar} alt={user.name} />
      </div>
      {viewLevel && (
        <div className="level">
          <img src="/assets/Level-armor.png" alt="Level armor" />
          <span>{user.level}</span>
        </div>
      )}
    </StyledAvatar>
  );
};

export default Avatar;
