import styled from "styled-components";
import { StyledFlex } from "../../../style/common";

export const StyledAvatarContent = styled(StyledFlex)`
  .info {
    font-family: "Cairo", sans-serif;
    text-align: right;
    font-style: normal;
    line-height: normal;
    padding-top: 5px;
  }
  .info h1 {
    color: var(--white-color);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: -8px;
  }

  .info p {
    color: var(--gold1-color);
    font-size: 14px;
    font-weight: 400;
  }

  @media (max-width: 668px) {
    .info h1 {
      font-size: 15px;
    }

    .info p {
      font-size: 12px;
    }
  }
`;
