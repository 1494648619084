import { useInfoContext } from "../../../contexts/info";
import Avatar from "../Avatar";
import { StyledAvatarContent } from "./style";

const AvatarContent = () => {
  const {user , setUser} = useInfoContext()
  return (
    <StyledAvatarContent gap="7px">
      <div className="info">
        <h1>{user.name}</h1>
        <p>iD: {user.id}</p>
      </div>
      <Avatar parentwidth="51px" parentheight="52px" />
    </StyledAvatarContent>
  );
};

export default AvatarContent;
