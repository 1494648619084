import styled from "styled-components";
import { StyledFlexCenter } from "../../../style/common";

export const StyledWinningResultCard = styled(StyledFlexCenter)`
  background-image: url("assets/winners-box.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* width: 21.259px;
  height: 20.651px; */
  width: 45.259px;
  height: 44.651px;
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* width: 13.97px;
    height: 15.185px; */
    width: 33.97px;
    height: 35.185px;
  }
`;
