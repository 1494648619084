import styled from "styled-components";
import { StyledPage } from "../../style/common";

export const StyledHomePage = styled(StyledPage)`
  font-family: "Cairo", sans-serif;
  min-height: 680px;
  position: relative;

  @media (min-width: 351px) {
    & {
      width: fit-content;
    }
  }

  .content__box {
    background-image: url("assets/background-box.png");
    margin: auto;
    padding-top: 18px;
    position: relative;
    height: 100%;
  }

  .logo {
    position: absolute;
    top: -105px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    width: 292px;
    z-index: 10000;
  }

  .WavySide {
    position: absolute;
    width: 619px;
    height: 41px;
    top: 45%;
    transform: translateY(-45%);
    mix-blend-mode: screen;
  }

  .WavySide-left {
    left: -295px;
    transform: rotate(-90deg);
  }

  .WavySide-right {
    right: -295px;
    transform: rotate(-90deg);
  }

  .level__box {
    align-items: center;
    position: relative;
    width: calc(100% - 90px);
    height: 150px;
    border-radius: 45px 45px 8px 8px;
    background-color: var(--purple3);
    transform: translateY(17px);
    box-shadow: inset 0 4px 3px 3px #250440;
  }

  .progress {
    position: relative;
  }

  .progress img {
    max-width: 100%;
    width: 70.717px;
    position: absolute;
    left: -10px;
    top: 30%;
    transform: translateY(-50%);
    z-index: 10000;
  }

  .progres {
    background-image: url("assets/progress-tube.png");
    width: 178.934px;
    height: 20.24px;
    border-radius: 6px;
    position: relative;
  }

  .progres span {
    background: linear-gradient(
      180deg,
      #d35a03 0%,
      #ee9201 36.45%,
      #f5bf09 100%
    );
    width: ${props => props.level / 100 * 100 + (props.level <= 20 ? 20 : 0)}%;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    border-radius: 20px;
  }

  .progress p {
    color: var(--white-color);
    text-align: right;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-right: 8px;
    margin-bottom: -2px;
  }

  .money {
    color: var(--white-color);
    font-family: "Cairo", sans-serif;
    text-align: right;
    font-style: normal;
    line-height: normal;
    padding: 0 4px 0 3px;
  }

  .money p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: -16px;
  }

  .money h3 {
    font-size: 28px;
    font-weight: 700;
    padding: 0;
  }

  .wallet .image img {
    max-width: 100%;
    width: 5rem;
  }

  .column1,
  .column2 {
    background-color: var(--third-color);
  }

  .column1 {
    width: 1.021px;
    height: 2.7rem;
  }

  .column2 {
    width: 0.624px;
    height: 2.5rem;
  }

  .game_content {
    flex-wrap: wrap;
  }

  .counter {
    flex-direction: column;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    transform: translate(-50%, -50%);
    gap: 30px;
  }
  .counter .counter-clock{
    width: 100%;
  }

  .counter img.clock {
    width: 24px;
    height: 27px;
    display: block;
    margin-top: 25px;
    z-index: 1;
    margin-left: 10px;
  }

  .counter img.counter {
    width: 90px;
    margin-left: 20px;
  }

  .counter .text {
    position: relative;
    margin-top: -33px;
  }

  .counter .text span {
    color: var(--gold1-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .counter .text p {
    color: var(--white-color);
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .counter .text img {
    width: 16.029px;
    height: 15.086px;
    border-radius: 32px;
  }
  .luck_button {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    outline: none;
    z-index: 10000;
    cursor: pointer;
  }

  .luck_button .image {
    background-image: url("assets/lucky-button.png");
    width: 122.25px;
    height: 44.455px;
    position: relative;
  }

  .luck_button .image span {
    color: var(--purple1);
    text-align: center;
    font-size: 17px;
    font-family: "Cairo", sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 122.9%; /* 18.435px */
  }

  .luck_button .image::before {
    content: "";
    position: absolute;
    background-image: url("assets/button-overlay.png");
    width: 100.023px;
    height: 20.005px;
  }

  .content__box,
  .progres,
  .luck_button .image,
  .luck_button .image::before {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .content{
    height : 100%;
  }
  
  .boxes-container{
    display: flex;
    width: 86%;
    margin: auto;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 3px;
    position: relative;
    height: calc(100% - 140px);
    .flex-space{
      display: flex;
      width: 49%;
      height: 29.3%;
      padding: 0;
      .box{
        height: 100%;
        width: 50%;
      }
      &.end{
        justify-content: flex-end;
      }
    }
  }

  @media (min-width: 320px) and (max-width : 359px){
    .boxes-container{
      height : calc(100% - 127px);
      .box{
        min-height: 55px;
        height: 55px;;

      }
      .flex-space{
        height : 55px;
      }
    }
  } 
  @media (min-width: 400px) and (max-width : 500px){
    .boxes-container{
      height : calc(100% - 156px);
    .box{
      height: 26.3%;
      .rate , & > img{
        scale : 1.1;
      }
    }
    .flex-space{
      height : 26.3% !important;
    }
  }
  }
  @media (min-width: 500px) and (max-width : 700px){
    .boxes-container{
      height : calc(100% - 175px);
    .box{
      height: 24.5%;
      .rate , & > img{
        scale : 1.25;
    }
    & > img {
      left: 57%;
      top: 56%;
    }
    
  }
  .flex-space {
      height : 24.5% !important;
    }
  }
}
  
  @media (min-width: 700px) and (max-width : 768px){
    .boxes-container{
      height : calc(100% - 175px);
    .box{
      height: 23.5%;
      .rate , & > img{
        scale : 1.25;
    }
      & > img {
        left: 57%;
        top: 56%;
      }
    }
    .flex-space{
      height : 23.5% !important;
    }
  }
  }
  //----------------------------------------------------------------------
  //-------------------------- Media Query -------------------------------
  //----------------------------------------------------------------------

  //------------------- Blue Box (Game Box) ---------------------
  @media (min-width: 320px) {
    .content__box {
      width: 280.523px;
      height: 346.65px;
    }

    .level__box {
      height: 80px;
      width: calc(100% - 45px);
      transform: translateY(-1px);
    }

    .progress img {
      width: 35.717px;
    }

    .progres {
      width: 85.934px;
      height: 10.24px;
    }

    .progress p {
      font-size: 9px;
      margin-right: 10px;
    }

    .money p {
      font-size: 6px;
      margin-bottom: -5px;
    }

    .money h3 {
      font-size: 13px;
    }

    .wallet .image img {
      width: 2rem;
    }

    .column1 {
      height: 1.3rem;
    }

    .column2 {
      height: 1rem;
    }
  }

  @media (min-width: 351px) {
    .content__box {
      width: 328.523px;
      height: 407.65px;
    }

    .level__box {
      height: 90px;
      width: calc(100% - 50px);
      transform: translateY(2px);
    }

    .progress img {
      width: 40.717px;
    }

    .progres {
      width: 105.934px;
      height: 13.24px;
    }

    .progress p {
      font-size: 10px;
    }

    .money p {
      font-size: 8px;
      margin-bottom: -10px;
    }

    .money h3 {
      font-size: 15px;
    }

    .wallet .image img {
      width: 2.1rem;
    }

    .column1 {
      height: 1.7rem;
    }

    .column2 {
      height: 1.3rem;
    }
  }

  @media (min-width: 365px) {
    .content__box {
      width: 340.523px;
      height: 419.65px;
    }

    .level__box {
      height: 95px;
      width: calc(100% - 50px);
      transform: translateY(3px);
    }

    .progres {
      width: 110.934px;
    }

    .money h3 {
      font-size: 16px;
    }

    .wallet .image img {
      width: 2.5rem;
    }

    .column1 {
      height: 2rem;
    }

    .column2 {
      height: 1.7rem;
    }
  }

  @media (min-width: 400px) {
    .content__box {
      width: 370.523px;
      height: 456.65px;
    }

    .level__box {
      height: 100px;
      width: calc(100% - 55px);
      transform: translateY(4px);
    }

    .progress img {
      width: 50.717px;
    }

    .progres {
      width: 130.934px;
      height: 13.24px;
    }

    .progress p {
      font-size: 12px;
      margin-right: 16px;
    }

    .money p {
      font-size: 8px;
      margin-bottom: -10px;
    }

    .money h3 {
      font-size: 17px;
    }

    .wallet .image img {
      width: 3rem;
    }
    .counter .clock{
      width : 30px !important;
      height: 33px !important;
      margin-left : 0 !important;
    }
    .counter .counter{
      width: 112px !important;
    }
  }

  @media (min-width: 430px) {
    .content__box {
      width: 400.523px;
      height: 495.65px;
    }

    .level__box {
      height: 100px;
      width: calc(100% - 55px);
      transform: translateY(7px);
    }

    .progres {
      width: 140.934px;
      height: 15.24px;
    }

    .progress p {
      font-size: 13px;
      margin-right: 18px;
    }

    .money p {
      font-size: 10px;
    }

    .money h3 {
      font-size: 19px;
    }

    .column1 {
      height: 2.3rem;
    }

    .column2 {
      height: 2rem;
    }
  }

  @media (min-width: 470px) {
    .content__box {
      width: 430.523px;
      height: 532.65px;
    }

    .level__box {
      height: 110px;
      width: calc(100% - 55px);
      transform: translateY(7px);
    }

    .progress img {
      width: 55.717px;
    }

    .progres {
      width: 157.934px;
    }

    .progress p {
      margin-right: 22px;
    }

    .money p {
      font-size: 11px;
    }

    .money h3 {
      font-size: 20px;
    }

    .wallet .image img {
      width: 4rem;
    }

    .column1 {
      height: 2.3rem;
    }
  }

  @media (min-width: 510px) {
    .content__box {
      width: 460.523px;
      height: 569.65px;
    }
    .level__box {
      height: 120px;
      width: calc(100% - 60px);
      transform: translateY(11px);
    }
    .counter .clock{
      width : 40px !important;
      height: 43px !important;
      margin-left : -15px !important;
    }
    .counter .counter{
      width: 122px !important;
    }
  }

  @media (min-width: 550px) {
    .content__box {
      width: 490.523px;
      height: 606.65px;
    }

    .level__box {
      height: 120px;
      width: calc(100% - 60px);
      transform: translateY(11px);
    }
  }

  @media (min-width: 600px) {
    .content__box {
      width: 520.523px;
      height: 642.65px;
    }

    .level__box {
      height: 120px;
      width: calc(100% - 70px);
      transform: translateY(14px);
    }

    .progress img {
      width: 59.717px;
    }

    .progres {
      width: 165.934px;
      height: 16.24px;
    }

    .progress p {
      font-size: 14px;
    }

    .money p {
      font-size: 12px;
      margin-bottom: -16px;
    }

    .money h3 {
      font-size: 23px;
    }

    .wallet .image img {
      width: 4.5rem;
    }

    .column1 {
      height: 2.5rem;
    }

    .column2 {
      height: 2.3rem;
    }
  }

  @media (min-width: 650px) {
    .content__box {
      width: 550.523px;
      height: 681.65px;
    }

    .level__box {
      height: 120px;
      width: calc(100% - 80px);
    }

    .progress img {
      width: 65.717px;
    }

    .progres {
      width: 175.934px;
      height: 18.24px;
    }

    .money p {
      font-size: 13px;
    }

    .money h3 {
      font-size: 25px;
    }

    .wallet .image img {
      width: 4.7rem;
    }
    .counter .clock{
      width : 50px !important;
      height: 53px !important;
      margin-left : -35px !important;
    }
    .counter .counter{
      width: 142px !important;
    }
    .counter .text {
      transform: scale(1.4);
    }
  }

  @media (min-width: 700px) {
    .content__box {
      width: 580.523px;
      height: 716.65px;
    }

    .level__box {
      height: 130px;
    }
  }

  @media (min-width: 730px) {
    .content__box {
      width: 610.523px;
      height: 752.65px;
    }
  }

  //------------------- Logo ---------------------
  @media (max-width: 349px) {
    .logo {
      width: 220px;
      top: -77px;
      
    }
  }

  @media (min-width: 350px) and (max-width: 430px) {
    .logo {
      width: 292px;
      top: -105px;
    }
  }

  @media (min-width: 431px) and (max-width: 547px) {
    .logo {
      width: 330px;
      top: -120px;
    }
  }

  @media (min-width: 548px) and (max-width: 768px) {
    .logo {
      width: 400px;
      top: -137px;
    }
  }

  //------------------- WavySide -------------------
  @media (max-width: 349px) {
    .WavySide {
      width: 275px;
      top: 40%;
    }

    .WavySide-left {
      left: -128px;
    }

    .WavySide-right {
      right: -136px;
    }
  }

  @media (min-width: 350px) and (max-width: 399px) {
    .WavySide {
      width: 350px;
    }

    .WavySide-left {
      left: -167px;
    }

    .WavySide-right {
      right: -167px;
    }
  }

  @media (min-width: 400px) and (max-width: 469px) {
    .WavySide {
      width: 422px;
    }

    .WavySide-left {
      left: -195px;
    }

    .WavySide-right {
      right: -205px;
    }
  }

  @media (min-width: 470px) and (max-width: 511px) {
    .WavySide {
      width: 475px;
    }

    .WavySide-left {
      left: -225px;
    }

    .WavySide-right {
      right: -225px;
    }
  }

  @media (min-width: 512px) and (max-width: 601px) {
    .WavySide {
      width: 540px;
    }

    .WavySide-left {
      left: -258px;
    }

    .WavySide-right {
      right: -258px;
    }
  }

  //---------------------------------------------------------------------
  //---------------------------- Repeating ------------------------------
  //---------------------------------------------------------------------

  @media (max-width: 768px) {
    .WavySide {
      position: absolute;
      height: 42px;
      top: 45%;
      transform: translateY(-45%);
      mix-blend-mode: screen;
    }

    .WavySide-left {
      transform: rotate(-90deg);
    }

    .WavySide-right {
      transform: rotate(-90deg);
    }
  }
`;
