import { styled } from "styled-components";

export const StyledContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 364px) {
    & {
      padding: 0;
    }
  }

  @media (min-width: 320px) {
    & {
      width: 300px;
    }
  }

  @media (min-width: 351px) {
    & {
      width: 330px;
    }
  }

  @media (min-width: 365px) {
    & {
      width: 360px;
    }
  }

  @media (min-width: 400px) {
    & {
      width: 390px;
    }
  }

  @media (min-width: 430px) {
    & {
      width: 425px;
    }
  }

  @media (min-width: 470px) {
    & {
      width: 460px;
    }
  }

  @media (min-width: 510px) {
    & {
      width: 500px;
    }
  }

  @media (min-width: 550px) {
    & {
      width: 540px;
    }
  }

  @media (min-width: 600px) {
    & {
      width: 590px;
    }
  }

  @media (min-width: 650px) {
    & {
      width: 620px;
    }
  }

  @media (min-width: 700px) {
    & {
      width: 680px;
    }
  }

  @media (min-width: 730px) {
    & {
      width: 700px;
    }
  }

`;
