import {
    StyledAlignFlex,
    StyledFlexCenter,
    StyledFlexCenterColumn,
} from "../../../style/common";
import CloseButton from "../../atoms/CloseButton";
import { StyledSuccessDrawer, StyledSuccessDrawerParent } from "./style";
import Avatar from "../../atoms/Avatar";
import { StyledGameItemCard } from "../../atoms/GameItemCard/style";
import BestPlayersCard from "../../atoms/BestPlayersCard";
import { useInfoContext } from "../../../contexts/info";
import { useEffect, useState } from "react";
import { STORAGE_URL } from "../../../api/server";

const SuccessDrawer = ({ handleSuccessDrawerClose, className, ...props }) => {
    const { user, roundPoint, selectedCard, bestWinners } = useInfoContext();
    const [rateSrc, setRateSrc] = useState("");
    useEffect(() => {
        switch (`${selectedCard.quantity}x`) {
            case "5x":
                setRateSrc("/assets/x5.png");
                break;
            case "10x":
                setRateSrc("/assets/x10.png");
                break;
            case "20x":
                setRateSrc("/assets/x20.png");
                break;
            case "45x":
                setRateSrc("/assets/x45.png");
                break;
            case "75x":
                setRateSrc("/assets/x75.png");
                break;
            default:
                setRateSrc("/assets/x5.png");
                break;
        }
    }, [selectedCard.quantity]);
    useEffect(() => {
        const audio = new Audio("/assets/audios/win.mp3");
        if (className === "display") {
            audio.play();
        } else {
            audio.pause();
        }
    }, [className]);
    return (
        <StyledSuccessDrawerParent className={className} {...props}>
            <StyledSuccessDrawer>
                <StyledAlignFlex gap="14.12px" className="title">
                    <div className="close_button">
                        <CloseButton
                            width="30px"
                            onClick={handleSuccessDrawerClose}
                        />
                    </div>
                    <StyledFlexCenterColumn className="name">
                        <Avatar />
                        <h2>{user.name}</h2>
                    </StyledFlexCenterColumn>
                    <div className="scarf_image">
                        <img
                            src="/assets/congratulations.gif"
                            alt="congratulations"
                            className="congratulations"
                        />
                    </div>
                </StyledAlignFlex>
                <StyledFlexCenterColumn className="content">
                    <StyledGameItemCard className="card">
                        <img
                            src={selectedCard.img}
                            alt={selectedCard.name}
                            className="item fruit-img"
                            style={{ maxWidth: "75px"}}
                        />
                        <div gap="2px" className="rate">
                            {selectedCard.quantity ? (
                                <img src={rateSrc} alt="" />
                            ) : (
                                <></>
                            )}
                        </div>
                    </StyledGameItemCard>
                    <div className="prize">
                        <span>لقد حصلت على</span>
                        <StyledFlexCenter gap="5px">
                            <img src="/assets/money2.gif" alt="money" />
                            <p>{roundPoint}</p>
                        </StyledFlexCenter>
                    </div>
                    {bestWinners.length > 0 ? (
                        <StyledFlexCenter gap="30px" className="best_players">
                            {bestWinners.map((u, index) => (
                                <BestPlayersCard
                                    key={index}
                                    backGround={
                                        u?.settings.length > 0
                                            ? STORAGE_URL + u?.settings[0].value
                                            : null
                                    }
                                    imageSrc={
                                        u?.profile_image != null
                                            ? STORAGE_URL + u?.profile_image
                                            : "assets/avatar.png"
                                    }
                                    playerName={`${u.first_name} ${u.last_name}`}
                                />
                            ))}
                        </StyledFlexCenter>
                    ) : (
                        <></>
                    )}
                </StyledFlexCenterColumn>
                <img
                    src="/assets/WavySide.gif"
                    alt="WavySide"
                    className="WavySide"
                />
            </StyledSuccessDrawer>
        </StyledSuccessDrawerParent>
    );
};

export default SuccessDrawer;
