import styled from "styled-components";
import { StyledFlexCenter } from "../../../style/common";

export const StyledKeyBoxCard = styled(StyledFlexCenter)`
  background-image: url("assets/value-box.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  /* height: 52.939px; */
  height: 100%;
  position: relative;
  box-shadow: 5px 5px 6px #250440;

  & > div {
    width: 100%;
    text-align: center;
    padding: 0 10px;
    position: relative;
    gap : 5px;
    justify-content : center;
  }

  & > div span {
    color: var(--secondary-color);
    font-family: "Cairo", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: 122.9%; /* 17.206px */
  }

  & > div img {
    max-width: 100%;
    width: 25px;
    border-radius: 41px;
  }
  
  @media (max-width: 399px) {
    height: 30.939px;

    & > div span {
      font-size: 15px;
    }

    & > div img {
      width: 13px;
    }
  }

  @media (min-width: 400px) and (max-width: 429px) {
    height: 35.939px;

    & > div span {
      font-size: 16px;
    }

    & > div img {
      width: 16px;
    }
  }

  @media (min-width: 430px) and (max-width: 469px) {
    height: 38.939px;

    & > div span {
      font-size: 19px;
    }

    & > div img {
      width: 19px;
    }
  }

  @media (min-width: 470px) and (max-width: 509px) {
    height: 40.939px;

    & > div span {
      font-size: 19px;
    }

    & > div img {
      width: 19px;
    }
  }

  @media (min-width: 510px) and (max-width: 599px) {
    height: 44.939px;

    & > div span {
      font-size: 20px;
    }

    & > div img {
      width: 20px;
    }
  }
`;
export const StyledButton = styled.button`
  border-radius: 10px;
  display: block;
  width : 23%;
  height : 10%;
  ${props => !props.selected ? `
    background-image: radial-gradient(50% 50% at 222.54% -10%, #1D076F 0%, #09053A 100%);
  `: `
    background-color : var(--gold4-color);
    & > div > div span{
    color : var(--purple8)
    }
  `}
    
`
