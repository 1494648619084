import { useEffect, useRef, useState } from "react";
import GameItemCard from "../../components/atoms/GameItemCard";
import KeyBox from "../../components/molecules/KeyBox";
import WinningResults from "../../components/molecules/WinningResults";
import {
    StyledAlignFlex,
    StyledBetweenFlex,
    StyledFlexCenter,
} from "../../style/common";
import { StyledHomePage } from "./styled";
import { useInfoContext } from "../../contexts/info";
import Cursor from "../../components/atoms/Cursor";
import { StartLoop, goToPosition } from "../../utils/loop";
import { useAudioContext } from "../../contexts/audio";
import { GAME_STORAGE_URL, STORAGE_URL, play } from "../../api/server";

const HomePage = ({ handleSuccessDrawerShow, showCounter, setShowCounter }) => {
    const {
        user,
        authToken,
        setUser,
        setSelectedCard,
        selectedCoin,
        gameItems,
        setRoundPoint,
        setBestWinners,
    } = useInfoContext();
    const { isMuted } = useAudioContext();
    const cardRefs = useRef([]);
    const cursorRef = useRef(null);
    const [audio, setAudio] = useState([]);
    // here the id of each card will be pushed when clicked
    const [selectedCards, setSelectedCards] = useState([]);
    const [lockCardClick, setLockCardClick] = useState(false);
    const [startGame, setStartGame] = useState(false);
    const [enableChanceBtn, setEnableChanceBtn] = useState(false);
    const [spendCoins, setSpendCoins] = useState(0);
    const [timer, setTimer] = useState(null);
    useEffect(() => {
        // timer of 30 secs
        if (showCounter) {
            setLockCardClick(false);
            setTimer(
                setTimeout(() => {
                    setLockCardClick(true);
                    setShowCounter(false);
                    setStartGame(true);
                    clearTimeout(timer);
                }, 1000 * 30)
            );
        }
    }, [showCounter]);
    // useEffect(() => {
    //     if (showCounter && timer != null) clearTimeout(timer);
    // }, [showCounter, timer]);
    useEffect(() => {
        if (startGame) {
            if (selectedCards.length === 0) {
                setLockCardClick(false);
                setShowCounter(true);
                setStartGame(false);
                return;
            }
            if (selectedCards.length < 3) {
                setSelectedCards([]);
                setLockCardClick(false);
                setShowCounter(true);
                setStartGame(false);
                user.coinsBalance += spendCoins;
                setSpendCoins(0);
                return;
            }
            const audio = new Audio("/assets/audios/wheel.mp3");
            setAudio((prev) => [audio, ...prev]);
            if (!isMuted) audio.play();
            const data = {
                is_chance: false,
                items: selectedCards,
                spendCoins: spendCoins,
                authToken: authToken,
            };
            StartLoop(cursorRef, cardRefs, data)
                .then((data) => {
                    const selected = data?.gift;
                    setSelectedCard({
                        name: selected.name_ar,
                        img: STORAGE_URL + selected.image,
                        quantity: null,
                    });
                    const userEdit = user;
                    userEdit["coinsBalance"] =
                        data?.user_coins ?? userEdit["coinBalance"];
                    userEdit["NumberOfRounds"] =
                        data?.rounds_number ?? userEdit["NumberOfRounds"] + 1;
                    setUser(userEdit);
                    setRoundPoint(data?.total_gifts ?? 0);
                    setEnableChanceBtn(data?.chance_btn ?? false);
                    setBestWinners(data?.best_winners ?? []);
                    audio.pause();
                    if (data?.is_win) handleSuccessDrawerShow();
                    else alert("You are lose");
                })
                .catch((err) => {
                    alert(err.data?.message ?? err);
                })
                .finally(() => {
                    setSelectedCards([]);
                    setStartGame(false);
                    setShowCounter(true);
                    setSpendCoins(selectedCoin);
                    audio.pause();
                });
        } else {
            setStartGame(false);
            setShowCounter(true);
        }
    }, [startGame]);
    // luck button click
    const handleLuckButton = () => {
        if (!enableChanceBtn) return;
        const data = {
            is_chance: true,
            items: [],
            spendCoins: 20,
            authToken: authToken,
        };
        play(data)
            .then((data) => {
                const userEdit = user;
                userEdit["coinsBalance"] =
                    data?.user_coins ?? userEdit["coinBalance"];
                setUser(userEdit);
                setRoundPoint(data?.total_gifts ?? 0);
                setEnableChanceBtn(data?.chance_btn ?? false);
                setBestWinners(data?.best_winners ?? []);
                setSelectedCards([]);
                setSpendCoins(selectedCoin);
                handleSuccessDrawerShow();
            })
            .catch((err) => alert(err.data?.message ?? err));
    };

    const handleCardClick = (id, index) => {
        if (lockCardClick || spendCoins === 30000) return;
        if (gameItems.find((e) => e.id === id).isLocked)
            return alert("This item is locked");
        if (spendCoins === 30000)
            return alert("You are reached to maximaze reach");
        let coins = selectedCoin + spendCoins;
        console.log("user current coins:", user["coinsBalance"]);
        const userCoins = user["coinsBalance"];
        if (userCoins - selectedCoin < 0) return;
        user["coinsBalance"] = userCoins - selectedCoin;
        console.log("user spended coins:", spendCoins);
        const audio = new Audio("/assets/audios/fruit-click.mp3");
        setAudio((prev) => [audio, ...prev]);
        if (!isMuted) audio.play();
        //cursor position
        goToPosition(index, cursorRef, cardRefs);
        const alreadySelected = selectedCards.includes(id);

        if (coins > 30000) coins = 30000;
        if (!alreadySelected) {
            if (selectedCards.length > 5)
                return alert("You're just can select six choises only");
            setSelectedCards((prev) => [...prev, id]);
        }
        setSpendCoins(coins);
    };
    useEffect(() => {
        if (isMuted) {
            audio.forEach((audio) => audio.pause());
        } 
    }, [audio, isMuted]);
    return (
        <StyledHomePage
            padding="90px 0 21px"
            margin="auto"
            level={user.progress}
        >
            {console.log(user.coinsBalance)}
            <div className="content__box">
                <img src="/assets/logo.gif" alt="logo" className="logo" />
                <img
                    src="/assets/WavySide.gif"
                    alt="WavySide"
                    className="WavySide WavySide-left"
                />
                <img
                    src="/assets/WavySide.gif"
                    alt="WavySide"
                    className="WavySide WavySide-right"
                />
                <div className="content">
                    <StyledBetweenFlex
                        className="level__box"
                        padding="30px 10px 0 20px"
                        margin="0 auto"
                    >
                        <div className="progress">
                            <p>{user.progress}/100</p>
                            <div className="progres">
                                <img
                                    src="/assets/progress-armor.png"
                                    alt="progress-armor"
                                />
                                <span></span>
                            </div>
                        </div>
                        <StyledAlignFlex gap="8px" className="wallet">
                            <div className="column1"></div>
                            <div className="money">
                                <p>محفظة الألعاب</p>
                                <h3>{user.coinsBalance}</h3>
                            </div>
                            <div className="column2"></div>
                            <div className="image">
                                <img src="/assets/money.gif" alt="money" />
                            </div>
                        </StyledAlignFlex>
                    </StyledBetweenFlex>
                    <div className="boxes-container">
                        {showCounter && (
                            <StyledFlexCenter className="counter">
                                <StyledAlignFlex
                                    gap="5px"
                                    className="counter-clock"
                                >
                                    <img
                                        src="/assets/clock.gif"
                                        alt="clock gif"
                                        className="clock"
                                    />
                                    <img
                                        src="/assets/counter.gif"
                                        alt="counter gif"
                                        className="counter"
                                    />
                                </StyledAlignFlex>
                                <StyledAlignFlex gap="3px" className="text">
                                    <span>{user.NumberOfRounds}</span>
                                    <p>| عداد الجولات</p>
                                    <img
                                        src="/assets/dribbble.gif"
                                        alt="dribbble gif"
                                    />
                                </StyledAlignFlex>
                            </StyledFlexCenter>
                        )}
                        {gameItems.map(
                            ({ id, image, name, quantity }, index) => {
                                if (index === 4 || index === 5) {
                                    return (
                                        <div
                                            className={`flex-space ${
                                                index === 5 ? "end" : ""
                                            }`}
                                            key={index + 1}
                                        >
                                            <GameItemCard
                                                key={id}
                                                itemSrc={
                                                    GAME_STORAGE_URL + image
                                                }
                                                itemAltSrc={name}
                                                rate={`${quantity}x`}
                                                className="box"
                                                ref={(el) =>
                                                    (cardRefs.current[index] =
                                                        el)
                                                }
                                                onClick={() =>
                                                    handleCardClick(id, index)
                                                }
                                                selected={selectedCards.includes(
                                                    id
                                                )}
                                            />
                                        </div>
                                    );
                                }
                                return (
                                    <GameItemCard
                                        key={id}
                                        itemSrc={GAME_STORAGE_URL + image}
                                        itemAltSrc={name}
                                        rate={`${quantity}x`}
                                        className="box"
                                        ref={(el) =>
                                            (cardRefs.current[index] = el)
                                        }
                                        onClick={() =>
                                            handleCardClick(id, index)
                                        }
                                        selected={selectedCards.includes(id)}
                                    />
                                );
                            }
                        )}
                        <Cursor top={"60%"} left={"65%"} ref={cursorRef} />
                    </div>
                    <button className="luck_button" onClick={handleLuckButton}>
                        <StyledFlexCenter className="image">
                            <span>ضربة حظ</span>
                        </StyledFlexCenter>
                    </button>{" "}
                </div>
            </div>
            <KeyBox />
            <WinningResults />
        </StyledHomePage>
    );
};

export default HomePage;
