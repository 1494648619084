import Header from "../../organism/Header";
import Footer from "../../organism/Footer";
import Container from "../../organism/Container";

const MainLayout = ({ children, handleDrawerShow }) => {
  return (
    <div>
      <Container>
        <Header handleDrawerShow={handleDrawerShow} />
        {children}
      </Container>
    </div>
  );
};

export default MainLayout;
