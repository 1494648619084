import { play, server } from "../api/server";

// util used to set cursor position
export const goToPosition = (number, cursorRef, cardRefs) => {
    if (cursorRef.current != null) {
        cursorRef.current.style.left =
            cardRefs.current[number].offsetLeft +
            cardRefs.current[number].getBoundingClientRect().width * 0.5 +
            "px";
        cursorRef.current.style.top =
            cardRefs.current[number].offsetTop +
            cardRefs.current[number].getBoundingClientRect().height * 0.5 +
            "px";
    }
};

// here u go
// will u invoke this , stop randomly on a card and return the index of the card
export const StartLoop = (cursorRef, cardRefs, data) => {
    return new Promise((resolve, reject) => {
        let i = 0;

        let interval = setInterval(() => {
            goToPosition(i, cursorRef, cardRefs);

            i++;
            if (i >= cardRefs.current.length) {
                i = 0;
            }
        }, 500);
        play(data)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => reject(err))
            .finally(() => clearInterval(interval));
    });
};
