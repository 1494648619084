import { StyledDrawer, StyledDrawerParent } from "./style";
import CloseButton from "../CloseButton";
import { StyledFlexCenterColumn } from "../../../style/common";

const Drawer = ({ className, onClick }) => {
  return (
    <StyledDrawerParent className={className}>
      <StyledDrawer gap="7px">
        <div className="close_button">
          <CloseButton width="35px" height="35px" onClick={onClick} />
        </div>
        <img
          src="/assets/drawer-photo.png"
          alt="drawerPhoto"
          className="drawerPhoto"
        />
        <StyledFlexCenterColumn padding="0 30px" className="drawer_text">
          <h1>قـواعد اللعب</h1>
          <div className="text_box">
            <div className="info">
              <h3> قواعد اللعبة</h3>
              <p>
                <span>
                  قم بتحديد عدد الكوينز ثم قم بتحديد العنصر المراد الحصول عليه
                  <span>-1</span>
                </span>
                <span>
                  يمكنك تحديد عدد يصل الى 6 عناصر في كل جولة كما يوجد حد أقصى
                  لعدد العملات
                  <span>-2</span>
                </span>
                <span>
                  كل جولة لديك 35 ثانية لاختيار العنصر وبعد 10 ثواني من السحب
                  سوف تظهر النتيجة
                  <span>-3</span>
                </span>
                <span>
                  اذا قمت باختيار العنصر الفائز سوف تحصل على الجوائز الخاصة به
                  <span>-4</span>
                </span>
                <span>
                  almachat حقوق اللعبة تنتمي إلى تطبيق
                  <span>-5</span>
                </span>
                <span>
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    <span> x45 و x75 أزرار</span>
                    <span>
                      {" "}
                      تكون مقفلة في بداية اللعبة حتى يصل اللاعب الى مستوى لفل
                      محدد
                    </span>
                    <span>
                      يتم فتحه عند الوصول لمستوى لفل 50 ويختار اللاعب زر آخر x45
                      زر ويقوم بقفله بدلآ من الذي تم فتح قفله
                    </span>
                    <span style={{ direction: "rtl" }}>
                      <span>زر x45 </span>
                      يتم فتحه عند الوصول لمستوى لفل 50 ويختار اللاعب زر آخر
                      ويقوم بقفله بدلآ من الذي تم فتح قفله
                    </span>
                    <span style={{ direction: "rtl" }}>
                      <span>زر x75 </span>
                      يتم فتحه عند الوصول لمستوى لفل 75 وايضا يقوم اللاعب بقفل
                      زر بدلا منه
                    </span>
                  </span>
                  <span>-6</span>
                </span>
                <span>
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    <span>
                      يحصل اللاعب على مستوى لفل خاص باللعبة عند وصول عدد الكوينز
                      الذي تم رميه داخل اللعبة الى 50800 كوينزة
                    </span>
                    <span>
                      وبعدها يبدأ اللفل بالارتفاع كلما ازدادت قيمة اللعب{" "}
                    </span>
                    <span>لفل 10 مقابل 61246 كوينز --</span>
                    <span>لفل 20 مقابل 82707 كوينز --</span>
                    <span>لفل 30 مقابل 123012 كوينز --</span>
                    <span>لفل 40 مقابل 201323 كوينز --</span>
                    <span>لفل 50 مقابل 362230 كوينز --</span>
                    <span>لفل 60 مقابل 715873 كوينز --</span>
                    <span>لفل 70 مقابل 1552633 كوينز --</span>
                    <span>لفل 75 مقابل 2367072 كوينز --</span>
                    <span>لفل 80 مقابل 3692414 كوينز --</span>
                    <span>لفل 90 مقابل 9620467 كوينز --</span>
                    <span>لفل 100 مقابل 25000000 كوينز --</span>
                  </span>
                  <span>-7</span>
                </span>
              </p>
            </div>
          </div>
        </StyledFlexCenterColumn>
      </StyledDrawer>
    </StyledDrawerParent>
  );
};

export default Drawer;
