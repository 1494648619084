import axios from "axios";
const baseURL = "https://alma-game.com/"
// const baseURL = "http://alma.test/";
export const server = axios.create({
    baseURL,
});
export const play = async (data) => {
    try {
        const token = data.authToken;
        delete data.authToken;
        const res = await server.post("api/fruit-games/play", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(res);
        return res.data?.data;
    } catch (err) {
        console.error(err.response);
        return err.response.data;
    }
};
export const STORAGE_URL = "https://back.alma-chat.com/storage/";
export const GAME_STORAGE_URL = baseURL + "storage/";
