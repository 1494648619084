import styled from "styled-components";
import { StyledBetweenAlignFlex } from "../../../style/common";

export const StyledHeader = styled(StyledBetweenAlignFlex)`
  .control img {
    max-width: 100%;
    width: 40px;
  }

  .control div {
    text-align: center;
    cursor: pointer;
  }

  .control p {
    color: var(--white-color);
    text-align: center;
    font-family: "Cairo", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 122.9%;
  }

  @media (max-width: 375px) {
    .control img {
      width: 26px;
    }

    .control p {
      font-size: 7px;
    }
  }

  @media (min-width: 376px) and (max-width: 425px) {
    .control img {
      width: 28px;
    }

    .control p {
      font-size: 9px;
    }
  }

  @media (min-width: 426px) and (max-width: 669px) {
    .control img {
      width: 30px;
    }

    .control p {
      font-size: 11px;
    }
  }
`;
