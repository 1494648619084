import styled from "styled-components";
import { StyledFlexCenter } from "../../../style/common";

export const StyledAvatar = styled(StyledFlexCenter)`
  position: relative;
  background-image: url("assets/avatar-border.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 80px;
  height: 82px;
  padding: 5px;

  img.stars {
    width: 44px;
    height: 28px;
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;
  }

  .image {
    position: relative;
    background-color: var(--pink-color);
    width: 65px;
    height: 65px;
    border-radius: 50%;
    overflow: hidden;
  }

  .image img {
    max-width: 100%;
    width: 64px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  .level {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -26px;
  }

  .level img {
    width: 34.816px;
    height: 27.724px;
    position: relative;
  }

  .level span {
    position: absolute;
    left: 50%;
    top: 37%;
    transform: translate(-50%, -50%);
    font-size: 11px;
    font-family: "Cairo", sans-serif;
    color: var(--mozzie-color);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--orange-color);
  }

  @media (max-width: 375px) {
    & {
      width: 51px;
      height: 52px;
    }

    img.stars {
      width: 34px;
      height: 18px;
      top: -9px;
    }

    .image {
      width: 43px;
      height: 43px;
    }

    .image img {
      width: 42px;
    }
  }

  @media (min-width: 376px) and (max-width: 612px) {
    & {
      width: 58px;
      height: 61px;
    }

    img.stars {
      width: 35px;
      height: 19px;
      top: -10px;
    }

    .image {
      width: 49px;
      height: 49px;
    }

    .image img {
      width: 50px;
    }
  }

  @media (min-width: 613px) and (max-width: 668px) {
    & {
      width: 73px;
      height: 75px;
    }

    img.stars {
      width: 40px;
      height: 24px;
      top: -12px;
    }

    .image {
      width: 65px;
      height: 65px;
    }

    .image img {
      width: 64px;
    }
  }
`;
