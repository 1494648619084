import { StyledBestPlayers } from "./style";
import { StyledFlexCenter } from "../../../style/common";

const BestPlayersCard = ({ imageSrc, playerName, backGround }) => {
    return (
        <StyledBestPlayers>
            <StyledFlexCenter className="image">
                <div className="frame">
                    <img src={imageSrc} alt="best players" className="person" />
                    {backGround != null ? (
                        <img src={backGround} alt="person" />
                    ) : (
                        <></>
                    )}
                </div>
            </StyledFlexCenter>
            <h3>{playerName}</h3>
        </StyledBestPlayers>
    );
};

export default BestPlayersCard;
