import { createContext, useContext, useEffect, useState } from "react";
import { STORAGE_URL, server } from "../api/server";

const infoContext = createContext();

export const useInfoContext = () => useContext(infoContext);
export default function InfoContextProvider({ children }) {
    const [user, setUser] = useState({
        name: "Yasser Ahmed ",
        id: "055655862",
        level: 10,
        progress: 27,
        avatar: "assets/avatar.png",
        coinsBalance: 30000,
        NumberOfRounds: 52225,
    });
    const [bestWinners, setBestWinners] = useState([]);
    const [gameItems, setGameItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [roundPoint, setRoundPoint] = useState(0);
    const [authToken, setAuthToken] = useState(
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FsbWEtZ2FtZS5jb20vYXBpL2xvZ2luIiwiaWF0IjoxNzEyMDgzOTQzLCJleHAiOjE3MTMyOTM1NDMsIm5iZiI6MTcxMjA4Mzk0MywianRpIjoia2Z2NkRxM1hkQUNobWJwbSIsInN1YiI6IjYzMjAiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.VVeiuZCuLdUEu6dAHIOCP-LNsLH2z3Rtuqf76NE7xPY"
    );
    const [selectedCoin, setSelectedCoin] = useState(10);
    const [selectedCard, setSelectedCard] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await server.get("api/fruit-game-settings", {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                const data = res.data?.data;
                const userData = data?.user;
                const game = data?.game;
                setUser({
                    name: `${userData?.first_name} ${userData?.last_name}`,
                    id: userData?.UID,
                    level: data?.current_level ?? 1,
                    progress: data?.current_step ?? 0,
                    avatar: userData?.profile_image != null ? STORAGE_URL + userData?.profile_image : "assets/avatar.png",
                    coinsBalance: userData?.coins,
                    NumberOfRounds: data?.rounds_number ?? 52225,
                });
                setGameItems(game);
            } catch (error) {
                console.error("Error fetching user data:", error);
                alert(error);
            }
            setIsLoading(false);
        };

        fetchData();
    }, [authToken]);
    return (
        <infoContext.Provider
            value={{
                user,
                setUser,
                isLoading,
                authToken,
                setAuthToken,
                gameItems,
                setIsLoading,
                selectedCard,
                setSelectedCard,
                roundPoint,
                setRoundPoint,
                selectedCoin,
                setSelectedCoin,
                bestWinners,
                setBestWinners,
            }}
        >
            {children}
        </infoContext.Provider>
    );
}
