import { useState } from "react";
import { WINNERS_BOX_DATA } from "../../../mock/WinnersBoxData";
import {
  StyledAlignFlex,
  StyledFlex,
  StyledFlexCenter,
} from "../../../style/common";
import WinningResultCard from "../../atoms/WinningResultCard";
import { StyledWinningResults } from "./style";
import { useAudioContext } from "../../../contexts/audio";

const WinningResults = () => {
  const { isMuted, toggleMute } = useAudioContext();
  const handleVoice = () => {
    toggleMute();
  };

  return (
    <StyledWinningResults gap="4px">
      <img
        src={
          !isMuted ? "/assets/voice-icon.png" : "/assets/voice-close-icon.png"
        }
        alt="voice-icon"
        className="voice"
        onClick={handleVoice}
      />
      <StyledFlexCenter
        padding="6px 10px 6px 6px"
        gap="5px"
        className="result_box"
      >
        <div className="box">
          <StyledAlignFlex gap="4px" padding="0 5px" className="child_box">
            {WINNERS_BOX_DATA.map(({ id, itemSrc, itemAltSrc }) => (
              <WinningResultCard
                key={id}
                imageSrc={itemSrc}
                imageAlt={itemAltSrc}
              />
            ))}
          </StyledAlignFlex>
        </div>
        <div className="text">
          <span>نتـــــائج</span>
          <span>الفــــوز</span>
        </div>
      </StyledFlexCenter>
      <StyledAlignFlex gap="5px">
        <div className="title">
          <span>أفضل الفائزين</span>
          <span>Best Winners</span>
        </div>
        <img src="/assets/Best-Winners.gif" alt="cup" />
      </StyledAlignFlex>
    </StyledWinningResults>
  );
};

export default WinningResults;
