import styled from "styled-components";
import { StyledFlexCenterColumn } from "../../../style/common";

export const StyledBestPlayers = styled(StyledFlexCenterColumn)`
  .image {
    background-image: url("assets/White-frame.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .frame {
    background-image: url("assets/gray-frame.svg");
    position: relative;
  }

  img.person {
    top: 50%;
    border-radius: 50%;
  }

  img.person,
  img.person + img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  h3 {
    color: var(--white-color);
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
  }

  //----------------------------------------------------------------------
  //-------------------------- Media Query -------------------------------
  //----------------------------------------------------------------------

  @media (min-width: 250px) and (max-width: 349px) {
    .image {
      width: 40.795px;
      height: 40.795px;
    }

    .frame {
      width: 13.758px;
      height: 14.565px;
    }

    img.person {
      width: 38px;
    }

    img.person + img {
      top: 71%;
      width: 80px;
    }

    h3 {
      font-size: 9px;
      margin-top: 10px;
    }
  }

  @media (min-width: 350px) and (max-width: 469px) {
    .image {
      width: 45.795px;
      height: 45.795px;
    }

    .frame {
      width: 17.758px;
      height: 18.565px;
    }

    img.person {
      width: 43px;
    }

    img.person + img {
      top: 71%;
      width: 85px;
    }

    h3 {
      font-size: 11px;
      margin-top: 10px;
    }
  }

  @media (min-width: 470px) and (max-width: 599px) {
    .image {
      width: 60.795px;
      height: 60.795px;
    }

    .frame {
      width: 28.758px;
      height: 29.565px;
    }

    img.person {
      width: 54px;
    }

    img.person + img {
      top: 71%;
      width: 105px;
    }

    h3 {
      font-size: 14px;
      margin-top: 10px;
    }
  }

  @media (min-width: 600px) and (max-width: 768px) {
    .image {
      width: 70.795px;
      height: 70.795px;
    }

    .frame {
      width: 38.758px;
      height: 39.565px;
    }

    img.person {
      width: 54px;
      /* background-color: red; */
    }

    img.person + img {
      top: 71%;
      width: 115px;
    }

    h3 {
      font-size: 16px;
      margin-top: 10px;
    }
  }
`;
