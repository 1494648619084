import React, { useState } from "react";
import HomePage from "../../../pages/HomePage";
import Drawer from "../../atoms/Drawer";
import MainLayout from "../../molecules/MainLayout";
import SuccessDrawer from "../../molecules/SuccessDrawer";
import { useInfoContext } from "../../../contexts/info";

const AppLayout = () => {
    const { isLoading } = useInfoContext();
    const [showGuidesDrawer, setShowGuidesDrawer] = useState(false);
    const [showSuccessDrawer, setShowSuccessDrawer] = useState(false);
    const [showCounter, setShowCounter] = useState(true);
    const handleGuidesDrawerShow = () => {
        setShowGuidesDrawer(true);
    };

    const handleGuidesDrawerClose = () => {
        setShowGuidesDrawer(false);
    };
    const handleSuccessDrawerShow = () => {
        setShowSuccessDrawer(true);
    };
    const handleSuccessDrawerClose = () => {
        setShowSuccessDrawer(false);
        setShowCounter(true);
    };

    return isLoading ? (
        <></>
    ) : (
        <>
            <MainLayout handleDrawerShow={handleGuidesDrawerShow}>
                <HomePage
                    handleSuccessDrawerShow={handleSuccessDrawerShow}
                    showCounter={showCounter}
                    setShowCounter={setShowCounter}
                />
            </MainLayout>
            <Drawer
                className={showGuidesDrawer ? "display" : "not_display"}
                onClick={handleGuidesDrawerClose}
            />
            <SuccessDrawer
                className={showSuccessDrawer ? "display" : "not_display"}
                handleSuccessDrawerClose={handleSuccessDrawerClose}
            />
        </>
    );
};

export default AppLayout;
