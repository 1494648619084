export const KEY_BOX_DATA = {
  "less": [
    {
      id: 1,
      value: 10,
    },
    {
      id: 2,
      value: 50,
    },
    {
      id: 3,
      value: 150,
    },
    {
      id: 4,
      value: 500,
    },
  ],
  "more": [
    {
      id: 1,
      value: 1000,
    },
    {
      id: 2,
      value: 1500,
    },
    {
      id: 3,
      value: 3000,
    },
    {
      id: 4,
      value: 10000,
    },
  ]
}