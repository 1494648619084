import styled from "styled-components";

export const StyledSuccessDrawerParent = styled.div`
  background: linear-gradient(
    180deg,
    #1f073f 15.17%,
    rgba(49, 2, 108, 0.46) 53.9%,
    #1f073f 113.85%
  );

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  overflow: hidden;
  /* display: none; */

  &.not_display {
    animation: hidden 1s ease-out forwards;
  }

  &.display {
    animation: show 1s ease-out forwards;
  }

  @keyframes show {
    0% {
      opacity: 0;
      display: block;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes hidden {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`;

export const StyledSuccessDrawer = styled.div`
  position: absolute;
  bottom: -235px;
  text-align: center;
  width: 100%;
  background-image: url("assets/SuccessBox.png");
  z-index: 1000000;

  .title {
    width: fit-content;
    margin: auto;
  }

  .close_button {
    position: absolute;
    top: 0px ;
    left: 76px ;
    z-index: 9999;
  }

  .name h2 {
    color: var(--white-color);
    font-weight: 700;
  }

  .scarf_image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("assets/scarf.png");
    filter: drop-shadow(3px 8px 11px 0px rgba(0, 0, 0, 0.25));
  }

  .scarf_image img {
    position: absolute;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%) rotate(-1.83deg);
  }

  .card {
    margin-bottom: 44px;
    position: relative;
  }
  .fruit-img{
    transform: translate(-22% ,-18% ) !important;
  }

  .card::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url("assets/Beam.gif");
    opacity: 0.6;
    mix-blend-mode: screen;
    z-index: -1;
    border-radius: 40% 40% 0 0;
  }

  .card::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    background-image: url("assets/money3.gif");
    mix-blend-mode: lighten;
  }

  .prize span {
    line-height: 122.9%;
  }

  .prize div {
    border: 1px solid var(--secondary-color);
    border-radius: 13px;
  }

  .prize img {
    border-radius: 41px;
  }

  .prize span,
  .prize p {
    color: var(--secondary-color);
    font-style: normal;
    font-weight: 800;
  }

  .best_players {
    position: relative;
  }

  .best_players::before {
    content: "";
    position: absolute;
    background: transparent;
    border: 1px solid var(--white-color);
    border-bottom: none;
    border-radius: 15px 15px 0 0;
  }

  .best_players::after {
    content: "أفضل اللاعبين";
    position: absolute;
    background-color: var(--white-color);
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--purple8);
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 130.9%; /* 14.399px */
  }

  img.WavySide {
    position: absolute;
    mix-blend-mode: screen;
  }

  &,
  .card::before,
  .scarf_image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .rate {
      left: 54% !important;
      bottom: -19px !important;
    }

    .rate img {
      scale : 1.5;
    }
    .card .item {
      /* width: 106px !important;
      height: 109px !important; */
      scale: 1.7;
    }


  //----------------------------------------------------------------------
  //-------------------------- Media Query -------------------------------
  //----------------------------------------------------------------------

  @media (max-width: 349px) {
    height: 35rem;
    bottom: 0px;
    .close_button {
      top: 84px;
      left: 7px;
    }

    .name {
      transform: translateY(-50px);
    }

    .name h2 {
      font-size: 20px;
    }

    .avatar {
      width: 90px;
      height: 92px;
      padding: 5px;
    }

    .avatar img.stars {
      width: 60px;
      height: 30px;
      top: -14px;
    }

    .avatar .image {
      width: 73px !important;
      height: 73px !important;
    }

    .avatar .image img {
      width: 65px;
      bottom: 0;
    }

    .scarf_image {
      top: 28px;
      width: 319.87px;
      height: 140.246px;
    }

    .scarf_image img {
      top: 50px;
      width: 231.71px;
    }

    .content {
      margin-top: 20px;
    }

    .card {
      width: 142px !important;
      height: 141px !important;
    }

    .card::before {
      width: 382px;
      height: 382px;
    }

    .card::after {
      width: 364px;
      height: 273px;
    }

    .card .item {
      /* width: 106px !important;
      height: 109px !important; */
      scale: 1.7;
    }

    

    .prize span {
      font-size: 9px;
    }

    .prize div {
      width: fit-content;
      padding: 0 10px;
      height: 40.154px;
    }

    .prize img {
      width: 28px;
      height: 28px;
    }

    .prize p {
      font-size: 28px;
    }

    .best_players {
      margin-top: 70px;
    }

    .best_players::before {
      width: 146px;
      height: 90%;
      top: -40px;
    }

    .best_players::after {
      top: -54px;
      padding: 4px 16px;
      font-size: 13px;
    }
  }

  @media (min-width: 350px) and (max-width: 399px) {
    height: 38rem;
    bottom: 0px;
    .close_button {
      top: 86px;
      left: 15px;
    }

    .content {
      margin-top: 25px;
    }

    .card {
      width: 162px !important;
      height: 161px !important;
    }

    .card::before {
      width: 382px;
      height: 382px;
    }

    .card::after {
      width: 364px;
      height: 273px;
    }


    .prize span {
      font-size: 11px;
    }

    .prize div {
      width: fit-content;
      padding: 0 10px;
      height: 45.154px;
    }

    .prize img {
      width: 34px;
      height: 34px;
    }

    .prize p {
      font-size: 34px;
    }
  }

  @media (min-width: 400px) and (max-width: 429px) {
    height: 41rem;
    bottom: 0px;
    .close_button {
      top: 87px;
      left: 25px;
    }

    .content {
      margin-top: 30px;
    }

    .card {
      width: 160px !important;
      height: 161px !important;
    }

   
  }

  @media (min-width: 430px) and (max-width: 469px) {
    height: 45rem;
    bottom: 0px;
    .close_button {
      top: 100px;
      left: 25px;
    }

    .scarf_image {
      top: 42px;
      width: 415.87px;
      height: 175.246px;
    }

    .scarf_image img {
      top: 68px;
      width: 289.71px;
    }

    .content {
      margin-top: 40px;
    }
  }

  @media (min-width: 470px) and (max-width: 509px) {
    height: 48rem;
    bottom: -7px;
    .close_button {
      top: 107px;
      left: 36px;
    }

    .content {
      margin-top: 50px;
    }
  }

  @media (min-width: 510px) and (max-width: 549px) {
    height: 53rem;
    bottom: -38px;
    .close_button {
      top: 120px;
      left: 41px;
    }

    .content {
      margin-top: 60px;
    }

    img.WavySide {
      bottom: -21px;
    }
  }

  @media (min-width: 550px) and (max-width: 599px) {
    height: 58rem;
    bottom: -90px;
    .close_button {
      top: 130px;
      left: 31px;
    }

    .name {
      transform: translateY(-70px);
    }

    .name h2 {
      font-size: 28px;
    }

    .avatar {
      width: 130px;
      height: 132px;
      padding: 5px;
    }

    .avatar img.stars {
      width: 90px;
      height: 45px;
      top: -23px;
    }

    .avatar .image {
      width: 110px !important;
      height: 110px !important;
    }

    .avatar .image img {
      width: 100px;
      bottom: 0;
    }

    .scarf_image {
      top: 53px;
      width: 488.87px;
      height: 218.246px;
    }

    .scarf_image img {
      top: 78px;
      width: 360.71px;
    }

    .content {
      margin-top: 70px;
    }

    .prize span {
      font-size: 12px;
    }

    .prize div {
      width: fit-content;
      padding: 0 10px;
      height: 50.154px;
    }

    .prize img {
      width: 36px;
      height: 36px;
    }

    .prize p {
      font-size: 36px;
    }

    img.WavySide {
      bottom: 26px;
    }
  }

  @media (min-width: 600px) and (max-width: 650px) {
    height: 63rem;
    bottom: -99px;
    .close_button {
      top: 145px;
      left: 40px;
    }

    .name {
      transform: translateY(-80px);
    }

    .name h2 {
      font-size: 30px;
    }

    .avatar {
      width: 150px;
      height: 152px;
      padding: 5px;
    }

    .avatar img.stars {
      width: 100px;
      height: 50px;
      top: -26px;
    }

    .avatar .image {
      width: 125px !important;
      height: 125px !important;
    }

    .avatar .image img {
      width: 127px;
      bottom: 0;
    }

    .scarf_image {
      top: 49px;
      width: 520.87px;
      height: 225.246px;
    }

    .scarf_image img {
      top: 75px;
      width: 380.71px;
    }

    .content {
      margin-top: 50px;
    }

    .prize span {
      font-size: 13px;
    }

    .prize div {
      width: fit-content;
      padding: 0 10px;
      height: 53.154px;
    }

    .prize img {
      width: 40px;
      height: 40px;
    }

    .prize p {
      font-size: 40px;
    }

    img.WavySide {
      bottom: 48px;
    }
  }

  @media (min-width: 651px) and (max-width: 699px) {
    height: 68rem;
    bottom: -173px;
    .close_button {
      left: 68px;
    }

    .scarf_image {
      top: 62px;
      width: 570.87px;
      height: 254.246px;
    }

    .scarf_image img {
      top: 90px;
      width: 424.71px;
    }

    .content {
      margin-top: 75px;
    }

    img.WavySide {
      bottom: 128px;
    }
  }

  @media (min-width: 700px) and (max-width: 768px) {
    height: 76rem;
    bottom: -235px;

    .scarf_image {
      top: 67px;
      width: 626.87px;
      height: 279.246px;
    }

    .scarf_image img {
      top: 92px;
      width: 470.71px;
    }

    .content {
      margin-top: 100px;
    }

    img.WavySide {
      bottom: 196px;
    }
  }

  //----------------------------------------------------------------------
  //------------------------- Repeating ----------------------------------
  //----------------------------------------------------------------------

  @media (min-width: 350px) and (max-width: 429px) {
    .name {
      transform: translateY(-50px);
    }

    .name h2 {
      font-size: 20px;
    }

    .avatar {
      width: 100px;
      height: 102px;
      padding: 5px;
    }

    .avatar img.stars {
      width: 70px;
      height: 35px;
      top: -17px;
    }

    .avatar .image {
      width: 80px !important;
      height: 80px !important;
    }

    .avatar .image img {
      width: 70px;
      bottom: 0;
    }
  }

  @media (min-width: 430px) and (max-width: 549px) {
    .name {
      transform: translateY(-60px);
    }

    .name h2 {
      font-size: 25px;
    }

    .avatar {
      width: 120px;
      height: 122px;
      padding: 5px;
    }

    .avatar img.stars {
      width: 80px;
      height: 40px;
      top: -17px;
    }

    .avatar .image {
      width: 100px !important;
      height: 100px !important;
    }

    .avatar .image img {
      width: 90px;
      bottom: 0;
    }
  }

  @media (max-width: 768px) {
    img.WavySide {
      width: 100%;
      height: 153px;
      left: 0;
    }
  }

  @media (min-width: 250px) and (max-width: 509px) {
    img.WavySide {
      bottom: -58px;
    }
  }

  @media (min-width: 350px) and (max-width: 469px) {
    .best_players {
      margin-top: 70px;
    }

    .best_players::before {
      width: 170px;
      height: 90%;
      top: -40px;
    }

    .best_players::after {
      top: -55px;
      padding: 4px 16px;
      font-size: 15px;
    }
  }

  @media (min-width: 470px) and (max-width: 599px) {
    .best_players {
      margin-top: 80px;
    }

    .best_players::before {
      width: 195px;
      height: 90%;
      top: -45px;
    }

    .best_players::after {
      top: -60px;
      padding: 4px 16px;
      font-size: 15px;
    }
  }

  @media (min-width: 600px) and (max-width: 768px) {
    .best_players {
      margin-top: 80px;
    }

    .best_players::before {
      width: 223px;
      height: 90%;
      top: -45px;
    }

    .best_players::after {
      top: -60px;
      padding: 5px 20px;
      font-size: 17px;
    }
  }

  @media (min-width: 400px) and (max-width: 549px) {
    .prize span {
      font-size: 12px;
    }

    .prize div {
      width: fit-content;
      padding: 0 10px;
      height: 50.154px;
    }

    .prize img {
      width: 36px;
      height: 36px;
    }

    .prize p {
      font-size: 36px;
    }
  }

  @media (min-width: 430px) and (max-width: 650px) {
    .card {
      width: 180px !important;
      height: 181px !important;
    }

  }

  @media (min-width: 400px) and (max-width: 768px) {
    .card::before {
      width: 400px;
      height: 400px;
    }

    .card::after {
      width: 433px;
      height: 303px;
    }


  }

  @media (min-width: 651px) and (max-width: 768px) {
    .name {
      transform: translateY(-80px);
    }

    .name h2 {
      font-size: 30px;
    }

    .avatar {
      width: 160px;
      height: 162px;
      padding: 5px;
    }

    .avatar img.stars {
      width: 114px;
      height: 62px;
      top: -31px;
    }

    .avatar .image {
      width: 130px !important;
      height: 130px !important;
    }

    .avatar .image img {
      width: 129px;
      bottom: 0;
    }

    .card {
      width: 200px !important;
      height: 201px !important;
    }


    .prize span {
      font-size: 15px;
    }

    .prize div {
      width: fit-content;
      padding: 0 10px;
      height: 57.154px;
    }

    .prize img {
      width: 45px;
      height: 45px;
    }

    .prize p {
      font-size: 46px;
    }
  }

  @media (min-width: 350px) and (max-width: 429px) {
    .scarf_image {
      top: 32px;
      width: 342.87px;
      height: 148.246px;
    }

    .scarf_image img {
      top: 52px;
      width: 263.71px;
    }
  }

  @media (min-width: 470px) and (max-width: 549px) {
    .scarf_image {
      top: 46px;
      width: 438.87px;
      height: 188.246px;
    }

    .scarf_image img {
      top: 68px;
      width: 322.71px;
    }
  }

  @media (min-width: 651px) and (max-width: 729px) {
    .close_button {
      top: 152px;
    }
  }
`;
