import { StyledWinningResultCard } from "./style";

const WinningResultCard = ({ imageSrc, imageAlt }) => {
  return (
    <StyledWinningResultCard className="WinningResultCard">
      <img src={imageSrc} alt={imageAlt} />
    </StyledWinningResultCard>
  )
}

export default WinningResultCard;