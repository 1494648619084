import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import InfoContextProvider from './contexts/info';
import AudioContextProvider from './contexts/audio';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <InfoContextProvider>
      <AudioContextProvider>
        <App />
      </AudioContextProvider>
    </InfoContextProvider>
  </React.StrictMode>
);

