import React, { useEffect } from "react";
import { StyledButton, StyledKeyBoxCard } from "./style";
import { StyledFlex } from "../../../style/common";
import { useInfoContext } from "../../../contexts/info";

const KeyBoxCard = ({ value ,selected , onClick}) => {
  const {setSelectedCoin} = useInfoContext()
  useEffect(() => { 
    if(selected) setSelectedCoin(value)
  }
  ,[selected])
  return (
    <StyledButton onClick={onClick} selected={selected}>
      <StyledKeyBoxCard  className="value_box">
      <StyledFlex padding="0 30px ">
        <span>{value}</span>
        <img src="/assets/money2.gif" alt="money" />
      </StyledFlex>
    </StyledKeyBoxCard>
    </StyledButton>
  );
};

export default KeyBoxCard;
