import { StyledCloseButton } from "./style";

const CloseButton = ({ onClick, ...props }) => {
  return (
    <StyledCloseButton {...props} onClick={onClick} className="control">
      <img src="/assets/close-icon.png" alt="close icon" />
      <p>إغلاق</p>
    </StyledCloseButton>
  );
};

export default CloseButton;
