import React from "react";
import { StyledValuesButton } from "./style";

const ValuesButton = ({ variant, ...props }) => {
  // variant = more || less
  // more take different background color than less
  return (
    <StyledValuesButton as="button" variant={variant} {...props}>
      {variant === "less" ? "قيم أقل" : "قيم أكثر"}
    </StyledValuesButton>
  );
};

export default ValuesButton;
